<template>
  <n-modal v-bind="$props" :width="500" sub-name="setting">
    <!-- prettier-ignore -->
    <Form ref="modalForm" class="n-modal-container" :label-width="150" :model="model" @submit.native.prevent>
      <n-field-text v-model="model.appName" label="appName" :error="error.appName" :disabled="disabled" />
      <n-field-text v-model="model.monitorKey" label="monitorKey" :error="error.monitorKey" :disabled="disabled" />
      <n-field-text v-model="model.monitorName" label="monitorName" :error="error.monitorName" :disabled="disabled" />
      <n-field-enum v-model="model.monitorLevel" label="monitorLevel" enum-name="MonitorLevel" :error="error.monitorLevel" :disabled="disabled" />
      <n-field-enum v-model="model.monitorPolicy" label="monitorPolicy" enum-name="MonitorPolicy" :error="error.monitorPolicy" :disabled="disabled || isEdit" />
      <n-field-text v-model="model.threshold" label="threshold" :error="error.threshold" :disabled="disabled" />
      <n-field-boolean v-model="model.enabled" label="enabled" :error="error.enabled" :disabled="disabled" />
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { updateGuard, addGuard, validateGuard } from '@/api/system/system-alert';

export default NModal.extend({
  name: 'ModalAlertSetting',
  components: { NModal },
  methods: {
    doValidate() {
      return validateGuard(this.model);
    },

    doSubmit() {
      return this.isCreate ? addGuard(this.model) : updateGuard(this.model);
    },
  },
});
</script>
