<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <nb-refresh @on-click="load" />
      <nb-add @on-click="onBtnAdd" />
    </template>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-1">{{ $t('label.appName') }}</th>
            <th class="col-weight-2">{{ $t('label.monitorName') }}</th>
            <th class="col-weight-2">{{ $t('label.monitorKey') }}</th>
            <th class="col-weight-1">{{ $t('label.monitorLevel') }}</th>
            <th class="col-weight-1">{{ $t('label.policy') }}</th>
            <th class="col-weight-1">{{ $t('label.threshold') }}</th>
            <th class="col-weight-1">{{ $t('label.enabled') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.appName | hyphen }}</td>
            <td>{{ row.monitorName | hyphen }}</td>
            <td>{{ row.monitorKey | hyphen }}</td>
            <td v-enums:MonitorLevel="row.monitorLevel"></td>
            <td v-enums:MonitorPolicy="row.monitorPolicy"></td>
            <td>{{ row.threshold | hyphen }}</td>
            <td><n-icon-boolean :value="row.enabled" /></td>
            <td class="n-button-box">
              <nb-modal @on-click="onBtnEdit(row)"></nb-modal>
              <nb-confirm text="remove" @on-ok="doRemove(row)"></nb-confirm>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--    <modal-alert-setting-->
    <!--      :model="record"-->
    <!--      @load="load"-->
    <!--      @modal-close="closeModal"-->
    <!--    ></modal-alert-setting>-->
  </index-content>
</template>

<script>
import SystemView from './view';
import { fetchGuard, delGuard } from '@/api/system/system-alert';
import { forEach } from 'lodash-es';
import { formatFromNow } from '@/helpers/date';
import ModalAlertSetting from './ModalAlertSetting';

export default SystemView.extend({
  name: 'SystemAlertSetting',
  components: { ModalAlertSetting },
  data() {
    return {
      preload: true,
      breadcrumbItems: [],
    };
  },

  mounted() {
    this.breadcrumbItems = [this.$t(`label.setting`)];
  },

  methods: {
    doLoad() {
      return fetchGuard();
    },

    doRemove(row) {
      return delGuard(row);
    },

    onBtnEdit(row) {
      this.createModal(ModalAlertSetting, { model: row, on: this });
    },

    onBtnAdd() {
      this.createModal(ModalAlertSetting, { model: { enabled: false }, on: this });
    },

    parse(rows) {
      forEach(rows, r => {
        r.uptimeText = formatFromNow(r.startupDatetime);
      });
      this.records = rows;
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 1149px;

  th.rw-action {
    width: 9rem;
  }
}
</style>
