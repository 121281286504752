import { $fetch, $post, $delete, $put } from '@/services/axios';

// alert
export function fetch(params) {
  return $fetch(`system/alerts`, params);
}

export function reset() {
  return $post(`system/reset`);
}

// setting
export function fetchGuard() {
  return $fetch('system/alert/guards');
}

export function validateGuard(data) {
  return $post(`system/alert/guard/validate`, data);
}

export function addGuard(data) {
  return $put(`system/alert/guard`, data);
}

export function delGuard(data) {
  return $delete(`system/alert/guard/${data.id}/${data.version}`);
}

export function updateGuard(data) {
  return $post(`system/alert/guard/${data.id}`, data);
}
